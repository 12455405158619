import {
  Button,
  Card,
  Chip,
  makeStyles,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import NavDrawer from "./NavDrawer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Convert } from "mongo-image-converter";
import articlePlaceholder from "../assets/images/articlePlaceholder.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory, useLocation } from "react-router-dom";
import {Alert} from "@material-ui/lab";
import UploadService from "../services/FileUploadService";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: theme.spacing(6),
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing(0)} auto`,
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(0),
    justifyContent: "space-around",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  addPostTitle: {
    fontSize: 30,
    padding: theme.spacing(1),
    fontVariant: "small-caps",
    fontWeight: 900,
    letterSpacing: "2px",
    width: "100%",
    color: "#FF6000",
    display: "flex",
    justifyContent: "flex-start",
  },
  edittingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 6,
    flexDirection: "column",
    height: "fit-content",
    paddingBottom: theme.spacing(4),
  },
  actionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 4,
    flexDirection: "column",
    height: "fit-content",
  },
  accordian: {
    width: 500,
    // background: "rgb(54, 86, 127)",
    margin: "0px 0px 24px 0px",
    color: "#000",
  },
  // pushNotiAcc: {
  //   width: 500,
  //   background: "linear-gradient(45deg, #e52d27, #b31217) !important",
  //   margin: "0px 0px 24px 0px",
  //   color: "#fff",
  // },
  // hospitalAcc: {
  //   width: 500,
  //   background: "linear-gradient(45deg, #2ca961, #2ed88a) !important",
  //   margin: "0px 0px 24px 0px",
  //   color: "#fff",
  // },
  // servicesAcc: {
  //   width: 500,
  //   // background: "linear-gradient(45deg, #ab5480, #d5aabf) !important",
  //   background: "linear-gradient(45deg, #4f5d64, #748892) !important",
  //   margin: "0px 0px 24px 0px",
  //   color: "#fff",
  // },
  // articlePicAcc: {
  //   width: 500,
  //   background: "linear-gradient(45deg, #F57C00, #FFB64D) !important",
  //   margin: "0px 0px 24px 0px",
  //   color: "#fff",
  // },
  accordHeading: {
    fontSize: 20,
    fontVariant: "small-caps",
    fontWeight: 500,
    letterSpacing: "2px",
    // color: "#000",
  },
  publishBtns: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingBottom: theme.spacing(2),
  },
  draftBtn: {
    background: "#FF6B00 !important",
    color: "#fff",
    "&:hover": {
      background: "#FF6B00 !important",
    },
  },
  previewBtn: {
    background: "#FF6B00 !important",
    color: "#fff",
    "&:hover": {
      background: "#FF6B00 !important",
    },
  },
  PublishDetails: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    background: "#e6e6e6",
    padding: 24,
  },
  publishField: {
    padding: theme.spacing(0.5),
    color: "#000 !important",
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  publishFieldInput: {
    color: "#000 !important",
  },
  publishStaus: {
    alignSelf: "flex-start",
    paddingBottom: theme.spacing(2),
    borderBottomColor: "#fff",
  },
  publishSchedule: {
    alignSelf: "flex-start",
    paddingBottom: theme.spacing(2),
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: "#cdcdcd",
    },
    "&:after": {
      borderBottomColor: "#cdcdcd",
    },
    "&:hover:before": {
      borderBottomColor: "#cdcdcd !important",
    },
  },
  PushNotiDetails: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    background: "#e6e6e6",
  },
  pushNotiTextField: {
    color: "#000",
    width: "100%",
  },
  pushNotiInput: {
    color: "#000",
    padding: theme.spacing(0.8),
  },
  pushTextUnderline: {
    "&:before": {
      borderBottom: "none",
    },
    "&:after": {
      borderBottom: "none",
    },
    "&:hover": {
      borderBottom: "none",
    },
    "&:hover:before": {
      borderBottom: "none",
    },
  },
  textFieldBox: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: "2px solid #cdcdcd",
    width: "100%",
  },
  AddCategory: {
    // padding: theme.spacing(1),
    color: "#365680 !important",
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  pushNotiTextArea: {
    color: "#000",
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: "transparent",
    border: "2px solid #cdcdcd",
    outline: "none",
  },
  selectHospitalField: {
    color: "#000",
    width: "100%",
  },
  optionHospitalField: {
    color: "#000",
  },
  hospitalChip: {
    maxWidth: "220px",
    textOverflow: "ellipsis",
    color: "#fff",
    backgroundColor: "#FF6B00",
    margin: theme.spacing(1.5),
    padding: theme.spacing(0.5),
  },
  serviceChip: {
    maxWidth: "220px",
    textOverflow: "ellipsis",
    color: "#fff",
    backgroundColor: "#FF6B00",
    margin: theme.spacing(1.5),
    padding: theme.spacing(0.5),
  },
  postTitleTextField: {
    color: "#fff",
    width: "100%",
  },
  postTitleInput: {
    color: "#000",
    padding: theme.spacing(0.8),
  },
  uploadBtn: {
    background: "#FF6B00 !important",
    color: "#fff",
    "&:hover": {
      background: "#FF6B00 !important",
    },
    margin: "20px 0px 20px 0px",
  },
  column: {
    padding: 24,
    background: "#f2f2f2",
    color: "#000",
  },
  detailsContainer: {
    paddingTop: 20,
    background: "#e6e6e6",
  },
  iconInput: {
    display: "none",
  },
  iconStyle: {
    height: 250,
    // width: 320,
  },
  imgContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  helperText: {
    fontSize: 11,
    padding: "10px 0px 0px 0px",
    margin: 0,
    letterSpacing: 1,
    color: "#365680",
    fontStyle: "italic",
    width: "100%",
    opacity: 0.8,
  },
  helperTextContainer: {
    padding: "0px 0px 20px 0px",
  },
}));

const AddPost = () => {
  const classes = useStyles();
  const history = useHistory();
  const fileRef = useRef();
  const [error, setError] = useState(null);
  const [hospitals, setHospitals] = useState([]);
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedContent, setSelectedContent] = useState([]);
  const [services, setServices] = useState([]);
  const [postContent, setPostContent] = useState("");
  const editorRef = useRef(null);
  const [baseHospitalArr, setBaseHospitalArr] = useState([]);
  const [serviceArr, setServiceArr] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);
  const [categoryArr, setCategoryArr] = useState([]);
  const [nonParentCategoryArr, setNonParentCategoryArr] = useState([]);
  const [selectedSubCategoryArr, setSelectedSubCategoryArr] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [order, setOrder] = useState('0');
  const initialCategoryState = {
    _id: "",
    categoryName: "",
    categoryParent: "",
    categoryColor: "",
    categoryIcon: "IconPlaceholder",
    author: localStorage.getItem("email"),
  };
  const [category, setCategory] = useState(initialCategoryState);
  const location = useLocation();
  const initialState = {
    _id: "",
    postTitle: "",
    postContent: "",
    postContentText: "",
    postServices: [],
    status: "None",
    baseHospitals: [],
    parentCategoryId: '',
    categoryId: '',
    author: localStorage.getItem("email"),
    articlePic: articlePlaceholder,
    postPushNotificationTitle: "",
    postPushNotificationMessage: "",
    folderName: new Date().getTime() + '_' + localStorage.getItem("email"),
    order: '0',
  };

  const [post, setPost] = useState(initialState);
  const [initial, setInitial] = useState(true);

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const progressInfosRef = useRef(null);

  const [serviceLoader, setServiceLoader] = useState(false);

  const selectFiles = (event) => {
    setSelectedFiles(event.target.files);
    setProgressInfos({ val: [] });
  };

  const upload = (idx, file) => {
    let folderName = post.folderName;
    let _progressInfos = [...progressInfosRef.current.val];
    return UploadService.upload(folderName, file, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      setProgressInfos({ val: _progressInfos });
    })
      .then(() => {
        setMessage((prevMessage) => ([
          ...prevMessage,
          {message: "Uploaded the file successfully: " + file.name, type: "success"},
        ]));
        fileRef.current.value = "";
        setTimeout(() => {
          setMessage([]);
          setProgressInfos({val: []});
        }, 5000);
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        setProgressInfos({ val: _progressInfos });

        setMessage((prevMessage) => ([
          ...prevMessage,
          {message: "Could not upload the file: " + file.name, type: "error"},
        ]));
        fileRef.current.value = "";
        setTimeout(() => {
          setMessage([]);
        }, 5000);
      });
  };

  const deleteFile = (fileName) => {
    UploadService.deleteFile(post.folderName, fileName).then((res) => {
      setMessage((prevMessage) => ([
        ...prevMessage,
        {message: "Deleted the file successfully: " + fileName, type: "info"},
      ]));
      UploadService.getFiles(post.folderName).then((response) => {
        setFileInfos(response.data);
      });
    });
    setTimeout(() => {
      setMessage([]);
    }, 5000);
  };

  const uploadFiles = () => {
    const files = Array.from(selectedFiles);

    let _progressInfos = files.map(file => ({ percentage: 0, fileName: file.name }));

    progressInfosRef.current = {
      val: _progressInfos,
    }

    const uploadPromises = files.map((file, i) => upload(i, file));

    Promise.all(uploadPromises)
      .then(() => UploadService.getFiles(post.folderName))
      .then((files) => {
        setFileInfos(files.data);
      });

    setMessage([]);
  };

  useEffect(() => {
    if (location !== undefined) {
      if (location.hasOwnProperty('state') ) {
        if (location.state !== undefined) {
          if (location.state.hasOwnProperty('folderName')) {
            if (location.state.folderName !== '') {
              UploadService.getFiles(location.state.folderName).then((response) => {
                if (response) {
                  if (response.hasOwnProperty('data')) {
                    setFileInfos(response.data);
                  }
                }
              });
            }
          }
        }
      }
    }
  },[location.state]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/base-hospitals`)
      .then((res) => {
        // console.log(res.data.hospitals);
        const allHospitals = res.data.hospitals;
        setBaseHospitalArr(allHospitals);
      })
      .catch((error) => {
        console.log(error);
      });
    setPost(initialState);

    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/categories`)
      .then((res) => {
        const allcategories = res.data.category;
        let finalCategories = [];
        let nonParentCategories = [];
        allcategories.forEach(category => {
          if (category.categoryParent === '') {
            finalCategories.push(category);
          } else {
            nonParentCategories.push(category);
          }
        })
        setCategoryArr(finalCategories);
        setNonParentCategoryArr(nonParentCategories);
      })
      .catch((error) => {
        console.log(error);
      });
    setCategory(initialCategoryState);
    //setUpdateCount(prevState => prevState + 1);
  }, []);

  useEffect(() => {
    if (location.state && editorRef.current && initial && serviceArr && serviceArr.length > 0) {
      let tempSelectedServices = [];
      let tempServices = [];
      serviceArr.forEach(service => {
        location.state.postServices.forEach(postService => {
          if (service.serviceName === postService.name) {
            tempSelectedServices.push(service);
            tempServices.push(postService);
          }
        });
      });
      setSelectedServices(tempSelectedServices);
      setServices(tempServices);
    }
  }, [serviceArr, location.state, editorRef.current])

  useEffect(() => {
    if (location.state && editorRef.current && initial) {
      setPost(location.state);
      let tempSelectedHospitals = [];
      let tempHospitals = [];
      let tempHospitalsIds = [];
      if (baseHospitalArr.length > 0) {
        baseHospitalArr.forEach(hospital => {
          location.state.baseHospitals.forEach(baseHospital => {
            if (hospital._id === baseHospital.id) {
              tempSelectedHospitals.push(hospital);
              tempHospitals.push(baseHospital);
              tempHospitalsIds.push(baseHospital.id);
            }
          });
        });
      }
      setHospitals(tempHospitals);
      setSelectedHospitals(tempSelectedHospitals);
      getServices(tempHospitalsIds);
      editorRef.current.setContent(location.state.postContent);
      setSelectedCategory(getCategoryNameById(location.state.parentCategoryId));
      let subCategories = [];
      nonParentCategoryArr.forEach(cat => {
        if (cat.categoryParent === location.state.parentCategoryId) {
          subCategories.push(cat);
        }
      });
      setSelectedSubCategoryArr(subCategories);
      setSelectedSubCategory(getSubCategoryNameById(location.state.categoryId));
      setOrder(location.state.order ? location.state.order : "0");
      setTimeout(() => {
        setUpdateCount(prevState => prevState + 1);
      }, 1000);
    }
  }, [location.state, editorRef.current, baseHospitalArr]);

  const getServices = hospitals => {
    if (hospitals.length > 0) {
      setServiceLoader(true);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}api/services/no-icon`, {hospitals: hospitals})
        .then((res) => {
          const allServices = res.data.service;
          // console.log(allServices);
          setServiceArr(allServices);
          setServiceLoader(false);
        })
        .catch((error) => {
          setServiceLoader(false);
          console.log(error);
        });
    } else {
      setServiceArr([]);
    }
  };

  const selectAllHospitalVal = () => {
    let localHospitals = [];
    let localHospitalsId = [];
    baseHospitalArr.forEach(hospital => {
      localHospitals.push({name: hospital.hospitalName, id: hospital._id});
      localHospitalsId.push(hospital._id);
    });
    setHospitals(localHospitals);
    setPost({...post, baseHospitals: localHospitals});
    setSelectedHospitals(baseHospitalArr);
    getServices(localHospitalsId);
  }

  const removeAllHospitalVal = () => {
    setHospitals([]);
    setPost({...post, baseHospitals: []});
    setSelectedHospitals([]);
    getServices([]);
  }

  const selectHospitalVal = (event, value, reason) => {
    setInitial(false);
    let tempHospitalsIds = [];
    if (reason === 'remove-option') {
      let tempHospitals = [];
      if (baseHospitalArr.length > 0) {
        baseHospitalArr.forEach(hospital => {
          value.forEach(baseHospital => {
            if (hospital.hospitalName === baseHospital.hospitalName) {
              tempHospitals.push({name: baseHospital.hospitalName, id: baseHospital._id});
              tempHospitalsIds.push(baseHospital._id);
            }
          });
        });
      }
      setPost({ ...post, baseHospitals: tempHospitals});
      setHospitals(tempHospitals);
      setSelectedHospitals(value);
    } else {
      let hospitalId = '';
      baseHospitalArr.forEach(hospital => {
        if (hospital.hospitalName === event.target.innerHTML) {
          hospitalId = hospital._id;
          setSelectedHospitals(prevState => [...prevState, hospital]);
        }
      });
      hospitals.forEach(hospital => {
        tempHospitalsIds.push(hospital.id);
      });
      tempHospitalsIds.push(hospitalId);
      setHospitals([...hospitals, { name: event.target.innerHTML, id: hospitalId}]);
      setPost({ ...post, baseHospitals: [...hospitals, { name: event.target.innerHTML, id: hospitalId }] });
    }
    getServices(tempHospitalsIds);
  };

  const selectAllServiceVal = () => {
    let localServices = [];
    serviceArr.forEach(service => {
      localServices.push({name: service.serviceName, id: service._id});
    });
    setServices(localServices);
    setPost({...post, postServices: localServices});
    setSelectedServices(serviceArr);
  };

  const removeAllServiceVal = () => {
    setServices([]);
    setPost({...post, postServices: []});
    setSelectedServices([]);
  }

  const selectServiceVal = (event, value, reason) => {
    setInitial(false);
    if (reason === 'remove-option') {
      let tempServices = [];
      if (serviceArr.length > 0) {
        serviceArr.forEach(service => {
          value.forEach(postService => {
            if (service.serviceName === postService.serviceName) {
              tempServices.push({name: service.serviceName, id: service._id});
            }
          });
        });
      }
      setPost({ ...post, postServices: tempServices});
      setServices(tempServices);
      setSelectedServices(value);
    } else {
      const getServiceId = serviceName => {
        let result = '';
        serviceArr.forEach(service => {
          if (service.serviceName === serviceName) {
            result = service._id;
          }
        });
        return result;
      }
      setServices([...services, { name: event.target.innerHTML, id: getServiceId(event.target.innerHTML) }]);
      setPost({
        ...post,
        postServices: [...services, { name: event.target.innerHTML, id: getServiceId(event.target.innerHTML) }],
      });
      serviceArr.forEach(service => {
        if (service.serviceName === event.target.innerHTML) {
          setSelectedServices(prevState => [...prevState, service]);
        }
      });
    }
  };

  const draftPostContent = () => {
    if (editorRef.current) {
      let content = editorRef.current.getContent();
      let textContent = editorRef.current.getContent({format: 'text'});
      setPostContent(content);
      setPost({ ...post, postContent: content, postContentText: textContent });
    }
  };
  const publishPostContent = () => {
    if (editorRef.current) {
      let content = editorRef.current.getContent();
      let textContent = editorRef.current.getContent({format: 'text'});
      setPostContent(content);
      setPost({ ...post, postContent: content, postContentText: textContent });
    }
  };

  const imgValue = async (e) => {
    // console.log(e.target.files[0]);
    // setHospital({ ...hospital, hospitalIcon: e.target.files[0] });
    try {
      const convertedImage = await Convert(e.target.files[0]);
      if (convertedImage) {
        // console.log(convertedImage);
        setPost({ ...post, articlePic: convertedImage });
      } else {
        alert("The file is not in .png format of image");
      }
    } catch (error) {
      console.warn(error.message);
    }
  };

  const onRemoveHospitalChip = (item) => {
    setHospitals(hospitals.filter((i) => i.name !== item.name));
  };

  const onRemoveServiceChip = (item) => {
    setServices(services.filter((i) => i.name !== item.name));
  };

  const handlePost = async (from) => {
    if (from === 'Draft') {
      draftPostContent();
    } else if (from === 'Publish') {
      publishPostContent();
    }
    if (
      post.postTitle !== "" && post.postContent !== ""
    ) {
      try {
        post.status = from;
        post.baseHospitals = hospitals;
        post.postServices = services;
        post.order = order;

        console.log('POST: ', JSON.stringify(post));
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/posts/add-post`,
          post
        );

        switch (response.status) {
          case 201:
            if (from === 'Publish' && post.postPushNotificationTitle.trim() !== "" && post.postPushNotificationMessage.trim() !== "") {

              let servicesIds = [];

              serviceArr.forEach(service => {
                post.postServices.forEach(pService => {
                  if (pService.name === service.serviceName) {
                    servicesIds.push(service._id);
                  }
                })
              });

              let notification = {
                  title: post.postPushNotificationTitle.trim(),
                  body: post.postPushNotificationMessage.trim(),
                  author: localStorage.getItem("email"),
                  services: servicesIds,
                };
                axios.post(
                  `${process.env.REACT_APP_BASE_URL}api/push-notifications/add-pushNotification`,
                  notification
                ).then(response => {
                  console.log(response);
                  history.push("/all-posts");
                }).catch(error => {
                  console.log(error);
                  history.push("/all-posts");
                });
            } else {
              history.push("/all-posts");
            }
            break;
          case 409:
            alert(response.data.errors);
            break;
          case 500:
            alert(response);
            break;
          case 400:
            alert(response);
            break;
          case 202:
            alert(response);
            break;
          default:
            break;
        }
      } catch (error) {
        setError(true);
        const errorMessage = 'Failed to add post.';
        alert(errorMessage);
      }
    } else {
      alert('Please fill all fields');
      setError(true);
    }
  };

  const getDefaultHospitals = () => {
    if (post && post.baseHospitals && post.baseHospitals.length > 0) {
      let previousBaseHospitalArray = [];
      post.baseHospitals.forEach(hospital => {
        previousBaseHospitalArray.push(hospital.name);
      })
      return previousBaseHospitalArray;
    } else {
      return undefined
    }
  }
  const getDefaultServices = () => {
    if (post) {
      return [post.postServices].flat().map(service => service);
    } else {
      return undefined
    }
  }

  const getIndexOfCategory = categoryName => {
    let returnValue = 0;
    for (let i = 0; i < categoryArr.length; i++) {
      if (categoryArr[i].categoryName === categoryName) {
        returnValue = i;
      }
    }
    return returnValue;
  }

  const getCategoryNameById = id => {
    let returnValue = '';
    for (let i = 0; i < categoryArr.length; i++) {
      if (categoryArr[i]._id === id) {
        returnValue = categoryArr[i].categoryName;
      }
    }

    return returnValue;
  }

  const getSubCategoryNameById = id => {
    let returnValue = '';
    for (let i = 0; i < nonParentCategoryArr.length; i++) {
      if (nonParentCategoryArr[i]._id === id) {
        returnValue = nonParentCategoryArr[i].categoryName;
      }
    }
    return returnValue;
  }

  const getIndexOfSubCategory = categoryName => {
    let returnValue = 0;
    for (let i = 0; i < selectedSubCategoryArr.length; i++) {
      if (selectedSubCategoryArr[i].categoryName === categoryName) {
        returnValue = i;
      }
    }
    return returnValue;
  }

  const getCategoryId = (categories, categoryName) => {
    let returnValue = '';
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].categoryName === categoryName) {
        returnValue = categories[i]._id;
      }
    }
    return returnValue;
  }

  return (
    <>
      <div className={classes.root}>
        <NavDrawer />
        {post && (
          <div className={classes.content}>
            <div className={classes.main}>
              <Typography className={classes.addPostTitle} variant="h4">
                Add New Post
              </Typography>
            </div>
            <div className={classes.main}>
              <div className={classes.edittingContainer}>
                <Card
                  elevation={10}
                  style={{ padding: 24, backgroundColor: "#F2F2F2" }}
                >
                  <div className={classes.textFieldBox}>
                    <TextField
                      key={`post-${updateCount}`}
                      className={classes.postTitleTextField}
                      placeholder="Post Title"
                      defaultValue={post.postTitle}
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.postTitleInput,
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => {
                        setPost({ ...post, postTitle: e.target.value });
                      }}
                    />
                  </div>
                  <Editor
                    // apiKey="8rtqchugdtsiydw3aqpdonzfxikmbrm0l9es45q165k4fkr2"
                    tinymceScriptSrc={process.env.REACT_APP_SELF_URL + 'tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    defaultValue={post.postContent}
                    // value={selectedContent}
                    scriptLoading={{ async: true }}
                    onChange={(e) => {
                      //setSelectedContent(e);
                      setPost({
                        ...post,
                        postContent: editorRef.current.getContent(),
                        postContentText: editorRef.current.getContent({format: 'text'}),
                      });
                    }}
                    init={{
                      placeholder: "Start typing...",
                      height: 500,
                      menubar: true,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | " +
                        "bold italic underline |" +
                        "formatselect | " +
                        "alignleft aligncenter alignright alignjustify | " +
                        "numlist bullist checklist | " +
                        "forecolor backcolor | " +
                        "pagebreak | " +
                        "charmap emoticons | " +
                        "preview | " +
                        "insertfile image  link",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                  <div>
                    <div style={{padding: 10}}>
                      <input ref={fileRef} type="file" accept="application/pdf, image/jpeg, image/png" multiple onChange={selectFiles} />
                      <button
                        disabled={!selectedFiles}
                        onClick={uploadFiles}
                      >
                        Upload
                      </button>
                    </div>
                    <div style={{padding: 10}}>
                      <div>Media:</div>
                      <ul>
                        {fileInfos &&
                        fileInfos.map((file, index) => (
                          <li className="list-group-item" key={index}>
                            <a href={file.url}>{file.name}</a> <span style={{paddingRight: 5,}}>
                            <CopyToClipboard text={file.url}>
                              <button style={{fontSize: 13}}>Copy URL</button>
                            </CopyToClipboard>
                          </span><button onClick={() => deleteFile(file.name)}>Delete</button>
                          </li>
                        ))}
                      </ul>
                      {progressInfos && progressInfos.val.length > 0 &&
                      progressInfos.val.map((progressInfo, index) => (
                        <div key={index}>
                          <span>{progressInfo.fileName}</span>
                          <LinearProgress variant="determinate" value={progressInfo.percentage} />
                        </div>
                      ))}
                    </div>
                    {message.length > 0 && (
                      <div style={{padding: 10}}>
                        {message.map((item, i) => {
                          return <Alert style={{paddingVertical: 4}} key={i} severity={`${item.type}`}>{item.message}</Alert>;
                        })}
                      </div>
                    )}
                  </div>
                </Card>
              </div>
              <div className={classes.actionContainer}>
                <div className={classes.accordian}>
                  <div>
                    <div className={classes.column}>
                      <Typography className={classes.accordHeading}>
                        Order
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.detailsContainer}>
                    <div className={classes.details}>
                      <div className={classes.textFieldBox}>
                        <TextField label="Order" type="number" value={order} onChange={(e) => {

                          setPost({ ...post,  order: e.target.value } );
                            setOrder(e.target.value);
                        }} />
                        {/*<NumericInput*/}
                        {/*  name='order'*/}
                        {/*  label='Order'*/}
                        {/*  value={order}*/}
                        {/*  onChange={(event) => {*/}
                        {/*    if (event.target.value > 0) {*/}
                        {/*      setPost({ ...post,  order: event.target.value } );*/}
                        {/*      setOrder(event.target.value);*/}
                        {/*    }*/}
                        {/*  }}*/}
                        {/*  variant='outlined'*/}
                        {/*/>*/}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.accordian}>
                  <div>
                    <div className={classes.column}>
                      <Typography className={classes.accordHeading}>
                        Base Hospital
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.detailsContainer}>
                    <div className={classes.details}>
                      <div className={classes.textFieldBox}>
                        <Autocomplete
                          multiple
                          className={classes.AddService}
                          options={baseHospitalArr}
                          //defaultValue={getDefaultHospitals()}
                          value={selectedHospitals}
                          getOptionLabel={(option) => option.hospitalName}
                          onChange={selectHospitalVal}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Select Base Hospital"
                              placeholder="Select Base Hospital"
                            />
                          )}
                        />
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 16}}>
                          <button style={{padding: 4}} onClick={selectAllHospitalVal}>Select All</button>
                          <button style={{padding: 4}} onClick={removeAllHospitalVal}>Reset</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classes.accordian}>
                  <div>
                    <div className={classes.column}>
                      <Typography className={classes.accordHeading}>
                        Services
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.detailsContainer}>
                    {serviceLoader && (
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 24}}>
                        <CircularProgress />
                      </div>
                    )}
                    {serviceLoader === false && (
                      <div className={classes.details}>
                        <div className={classes.textFieldBox}>
                          <Autocomplete
                            multiple
                            className={classes.AddService}
                            options={serviceArr}
                            defaultValue={getDefaultServices()}
                            getOptionLabel={(option) => option.serviceName}
                            value={selectedServices}
                            onChange={selectServiceVal}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Select Service"
                                placeholder="Select Service"
                              />
                            )}
                          />
                          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 16}}>
                            <button style={{padding: 4}} onClick={selectAllServiceVal}>Select All</button>
                            <button style={{padding: 4}} onClick={removeAllServiceVal}>Reset</button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className={classes.accordian}>
                  <div>
                    <div className={classes.column}>
                      <Typography className={classes.accordHeading}>
                        Category
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.detailsContainer}>
                    <div className={classes.textFieldBox}>
                      <Autocomplete
                        key={`category-parent-${updateCount}`}
                        className={classes.AddCategory}
                        options={categoryArr}
                       // defaultValue={categoryArr.length > 0 && category.categoryParent ? categoryArr[getIndexOfCategory(category.categoryParent)] : null}
                       // value={(categoryArr.length > 0 && category.categoryParent) ? categoryArr[getIndexOfCategory(category.categoryParent)] : null}
                        value={(categoryArr.length > 0 && selectedCategory !== '') ? categoryArr[getIndexOfCategory(selectedCategory)] : null}
                        getOptionLabel={(option) => option.categoryName}
                        onChange={(e) => {
                          if (e.target.innerHTML.charAt(0) === '<') {
                            setSelectedCategory('');
                            setSelectedSubCategory('');
                            setSelectedSubCategoryArr([]);
                          } else {
                            setPost({ ...post, parentCategoryId: getCategoryId(categoryArr, e.target.innerHTML)});
                            setSelectedCategory(e.target.innerHTML);
                            let subCategories = [];
                            let parentCategory = e.target.innerHTML;
                            nonParentCategoryArr.forEach(cat => {
                              if (cat.categoryParent === getCategoryId(categoryArr, parentCategory)) {
                                subCategories.push(cat);
                              }
                            });
                            setSelectedSubCategory('');
                            setSelectedSubCategoryArr(subCategories);
                          }

                          setUpdateCount(prevState => prevState + 1);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Select Category Parent"
                            placeholder="Select Category Parent"
                          />
                        )}
                      />
                    </div>
                    <div className={classes.textFieldBox}>
                      <Autocomplete
                        key={`category-parent-${updateCount}`}
                        className={classes.AddCategory}
                        options={selectedSubCategoryArr}
                       // defaultValue={selectedSubCategoryArr.length > 0 && category.categoryParent ? categoryArr[getIndexOfCategory(category.categoryParent)] : null}
                       // value={(categoryArr.length > 0 && category.categoryParent) ? categoryArr[getIndexOfCategory(category.categoryParent)] : null}
                        value={(selectedSubCategoryArr.length > 0 && selectedSubCategory !== '') ? selectedSubCategoryArr[getIndexOfSubCategory(selectedSubCategory)] : null}
                        getOptionLabel={(option) => option.categoryName}
                        onChange={(e) => {
                          if (e.target.innerHTML.charAt(0) === '<') {
                            setSelectedSubCategory('');
                          } else {
                            setPost({...post, categoryId: getCategoryId(selectedSubCategoryArr, e.target.innerHTML)});
                            setSelectedSubCategory(e.target.innerHTML);
                          }
                          setUpdateCount(prevState => prevState + 1);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Select Category"
                            placeholder="Select Category"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className={classes.accordian}>
                  <div>
                    <div className={classes.column}>
                      <Typography className={classes.accordHeading}>
                        Article Photo
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.detailsContainer}>
                    <div className={classes.imgContainer}>
                      <img
                        className={classes.iconStyle}
                        alt="icon"
                        src={post.articlePic}
                      />
                      <input
                        accept="image/*"
                        name="articlePic"
                        className={classes.iconInput}
                        id="articlePicId"
                        multiple
                        type="file"
                        onChange={(e) => {
                          imgValue(e);
                        }}
                      />
                      <label htmlFor="articlePicId">
                        <Button
                          startIcon={<PhotoCamera />}
                          variant="contained"
                          className={classes.uploadBtn}
                          component="span"
                        >
                          Upload
                        </Button>
                      </label>
                      <div className={classes.helperTextContainer}>
                        <p className={classes.helperText}>* .png or .jpg format only</p>
                        <p className={classes.helperText}>
                          * Image dimensions must be at least 234px X 234px
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classes.accordian}>
                  <div
                    expandIcon={<ExpandMoreIcon style={{ color: "#000" }} />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                  >
                    <div className={classes.column}>
                      <Typography className={classes.accordHeading}>
                        Push Notification
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.PushNotiDetails}>
                    <div className={classes.content}>The notification is send based on service selection</div>
                    <div className={classes.textFieldBox}>
                      <TextField
                        className={classes.pushNotiTextField}
                        placeholder="Push Notification Title"
                        defaultValue={post.postPushNotificationTitle}
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.pushNotiInput,
                          },
                        }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          setPost({ ...post, postPushNotificationTitle: e.target.value });
                        }}
                      />
                    </div>
                    <TextareaAutosize
                      rowsMin={15}
                      className={classes.pushNotiTextArea}
                      placeholder="Add Push Notification Message"
                      defaultValue={post.postPushNotificationMessage}
                      InputProps={{
                        classes: {
                          input: classes.pushNotiTextInput,
                        },
                      }}
                      onChange={(e) => {
                        setPost({ ...post, postPushNotificationMessage: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div className={classes.accordian}>
                  <div
                    className={classes.accordianTitle}
                    expandIcon={<ExpandMoreIcon style={{ color: "#000" }} />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                  >
                    <div className={classes.column}>
                      <Typography className={classes.accordHeading}>
                        Publish
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.PublishDetails}>
                    <div className={classes.publishBtns}>
                      <Button
                        variant="contained"
                        size="medium"
                        className={classes.draftBtn}
                        onClick={() => handlePost('Draft')}
                      >
                        Save Draft
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        className={classes.previewBtn}
                        onClick={() => {
                          history.push({pathname: "/preview", state: post});
                        }}
                      >
                        Preview
                      </Button>
                    </div>

                    <Typography className={classes.publishStaus}>
                      STATUS: {post.status}
                    </Typography>

                    <Typography className={classes.publishSchedule}>
                      SCHEDULE: {"Edit"}
                    </Typography>

                    <TextField
                      type="datetime-local"
                      className={classes.publishField}
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.publishFieldInput,
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                    />

                    <Button
                      variant="contained"
                      size="large"
                      className={classes.draftBtn}
                      onClick={() => handlePost('Publish')}
                    >
                      Publish
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AddPost;
