import {
  Button,
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import axios from "axios";
import MaterialDatatable from "material-datatable";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import NavDrawer from "./NavDrawer";
import moment from 'moment';

const SPACED_DATE_FORMAT = "DD MMM YYYY";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: theme.spacing(6),
    boxSizing: "initial",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing(0)} auto`,
    paddingTop: theme.spacing(4),
    // marginBottom: theme.spacing(4),
    justifyContent: "space-around",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  addBtn: {
    background: "#FF6B00 !important",
    color: "#fff",
    cursor: "pointer",
    letterSpacing: 2,
    "&:hover": {
      background: "#36567F !important",
    },
  },
  categoriesTitle: {
    fontWeight: 900,
    fontVariant: "small-caps",
    letterSpacing: "2px",
    color: "#FF6000",
  },
}));

const Categories = () => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedCategoryName, setSelectedCategoryName] = useState('');
  const [allCategoryArr, setAllCategoryArr] = useState([]);
  const [categoryArr, setCategoryArr] = useState([]);
  const [parentCategoryArr, setParentCategoryArr] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/categories`)
      .then((res) => {
        const allCategories = res.data.category;
        let parentCategories = [];
        allCategories.forEach(cat => {
          if (cat.categoryParent === '') {
            parentCategories.push(cat);
          }
        });
        setAllCategoryArr(allCategories);
        setParentCategoryArr(parentCategories);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const columns = [
    {
      name: "Category Name",
      field: "categoryName",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Author",
      field: "author",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Order",
      field: "order",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Date",
      field: "date",
      options: { filter: true, sort: false, customBodyRender: value =>
          moment(new Date(value.date)).format(SPACED_DATE_FORMAT) },
    },
    {
      name: "Actions",
      field: "action",
      options: {
        customBodyRender: value => {
          return (
            <>
              <Button
                style={{height: "30px"}}
                variant="outlined"
                color="primary"
                onClick={() => {
                  history.push({pathname: "/add-new-category", state: value});
                }}
              >
                Edit
              </Button>
              {selectedCategoryName === '' && (
                <Button
                  style={{height: "30px", marginLeft: 8}}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    // Set category arr using id
                    let subCategories = [];
                    allCategoryArr.forEach(cat => {
                      if (cat.categoryParent === value._id) {
                        subCategories.push(cat);
                      }
                    });
                    setCategoryArr(subCategories);
                    setSelectedCategoryId(value._id);
                    setSelectedCategoryName(value.categoryName);
                  }}
                >
                  Sub Categories
                </Button>
              )}
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    sortColumnIndex: 0,
    sortColumnDirection: "asc",
    onRowsDelete: (rowsDeleted) => {
      const array = rowsDeleted.data;
      array.forEach(({ rowIndex, dataIndex }) => {
        const category_id = selectedCategoryName === '' ? parentCategoryArr[dataIndex]._id : categoryArr[dataIndex]._id;
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}api/categories/delete-category/${category_id}`
          )
          .then((res) => {
            let newAllArr = allCategoryArr.filter(obj => obj._id !== category_id);
            let newParentCatArr = parentCategoryArr.filter(obj => obj._id !== category_id);
            let newCatArr = categoryArr.filter(obj => obj._id !== category_id);
            setAllCategoryArr(newAllArr);
            setParentCategoryArr(newParentCatArr);
            setCategoryArr(newCatArr);
          });
      });
    },
  };

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTypography: {
          root: {
            fontFamily: "Montserrat !Important",
            color: "#000",
            fontSize: 20,
            fontWeight: 700,
            fontVariant: "small-caps",
            letterSpacing: "2px",
          },
        },
        MuiTableCell: {
          root: {
            fontSize: 16,
            fontVariant: "small-caps",
            fontFamily: "Montserrat !Important",
          },
        },
        MaterialDatatableToolbar: {
          root: {
            fontFamily: "Montserrat !Important",
            background: "#F2F2F2",
          },
        },

        MuiTableHead: {
          root: {
            fontWeight: 700,
            fontSize: 16,
          },
        },
        MaterialDatatableToolbarSelect: {
          deleteIcon: {
            color: "red",
          },
          title: {
            color: "#000 !important",
          },
        },

        MuiIconButton: {
          root: {
            color: "#2B2828",
          },
        },
      },
    });

  return (
    <>
      <div className={classes.root}>
        <NavDrawer />
        <div className={classes.content}>
          <div className={classes.main}>
            <Typography className={classes.categoriesTitle} variant="h4">
              Category Management
            </Typography>
            <Button
              variant="contained"
              size="large"
              className={classes.addBtn}
              onClick={() => {
                let categoryWithParent = {
                  _id: "",
                  categoryName: "",
                  categoryParent: selectedCategoryId,
                  categoryColor: "#000",
                  categoryIcon: "IconPlaceholder",
                  author: localStorage.getItem("email"),
                  order: '0',
                };
                history.push({pathname: "/add-new-category", state: categoryWithParent});
              }}
            >
              Add New Category
            </Button>
          </div>
          <div className={classes.main}>
            <div style={{ width: "100%" }}>
              {selectedCategoryName !== '' && (
                <Button onClick={() => {
                  setSelectedCategoryName('');
                  setSelectedCategoryId('');
                  setCategoryArr([]);
                }}>
                  <ArrowBackIcon/> Back
                </Button>
              )}
              <MuiThemeProvider theme={getMuiTheme()}>
                <MaterialDatatable
                  title={selectedCategoryName === '' ? '' : selectedCategoryName}
                  data={selectedCategoryName === '' ? parentCategoryArr : categoryArr}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Categories;
