import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Fade,
  Grow,
  makeStyles,
  createStyles,
  InputAdornment,
} from "@material-ui/core";
import Hospital from "../assets/images/obhg_web_portal_background.jpg";
import Logo from "../assets/images/logo.png";
import "../assets/css/common.css";
import { useHistory } from "react-router-dom";
import MuiPhoneNumber from "material-ui-phone-number";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";

// styles
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        top: 0,
        left: 0,
      },
    },
    logotypeContainer: {
      backgroundImage: `url(${Hospital})`,
      backgroundSize: "cover",
      backgroundRepeat: "no repeat",
      width: "60%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "50%",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    logotypeText: {
      color: "white",
      fontWeight: 500,
      fontSize: 84,
      [theme.breakpoints.down("md")]: {
        fontSize: 48,
      },
    },
    formContainer: {
      width: "40%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "60%",
      },
    },
    form: {
      width: 320,
      padding: 24,
      justifyContent: "center",
      alignItems: "center",
      background: "#F1F1F1",
      borderRadius: 12,
      marginTop: 24,
    },
    greeting: {
      fontWeight: 500,
      marginTop: theme.spacing(4),
      color: "#FF6B00",
      letterSpacing: 3,
    },
    subGreeting: {
      fontWeight: 500,
      textAlign: "center",
      marginTop: theme.spacing(2),
    },
    title: {
      color: "#36567F",
      fontWeight: "700",
      letterSpacing: 1,
      fontSize: 24,
      marginBottom: 8,
    },
    subTitle: {
      color: "#36567F",
      letterSpacing: 1,
      fontSize: 16,
      marginBottom: 8,
      padding: "20px 0px 0px 0px",
      fontWeight: "bold",
    },
    contentText: {
      color: "#36567F",
      letterSpacing: 1,
      fontSize: 14,
      marginBottom: 8,
      padding: "10px 0px 0px 0px",
    },
    errorMessage: {
      textAlign: "center",
      fontSize: 12,
      fontWeight: "600",
      color: "red",
      padding: "20px 0px 0px 0px",
    },
    textFieldUnderline: {
      "&:before": {
        borderBottomColor: "#747272",
      },
      "&:after": {
        borderBottomColor: "#36567F",
      },
      "&:hover:before": {
        borderBottomColor: "#36567F !important",
      },
    },
    textField: {
      borderBottomColor: "#DC3545",
    },
    formButtons: {
      width: "100%",
      marginTop: theme.spacing(4),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    copyright: {
      bottom: theme.spacing(4),
      paddingBottom: 4,
      marginTop: 24,
      color: "#2B2828",
      whiteSpace: "nowrap",
      textAlign: "center",
      position: "absolute",
    },
    resetBtn: {
      backgroundColor: "#FF6B00",
      color: "#FFF",
      textTransform: "none !Important",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#365680",
      },
    },
    fieldIcons: {
      height: 24,
      width: 24,
      cursor: "none",
      pointerEvents: "none",
      color: "#FF6B00",
    },
    passErrMessage: {
      fontSize: 12,
      fontWeight: "600",
      color: "red",
      padding: "5px 0px 0px 0px",
    },
    passFieldIcons: {
      height: 24,
      width: 24,
      cursor: "pointer",
      color: "#FF6B00",
    },
  })
);

const ResetPassword = () => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const history = useHistory();
  const [emailValid, setEmailValid] = useState(true);
  const [showEmailValidity, setShowEmailValidity] = useState(false);

  const [user, setUser] = useState({
    email: "",
    phoneNumber: "",
  });

  const emailValue = (e) => {
    let trimmedValue = e.target.value.trim();
    setUser({ ...user, email: trimmedValue });
    // console.log(trimmedValue);
    const validateEmail = (email) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    };

    if (validateEmail(trimmedValue) === true && trimmedValue !== "") {
      // console.log("trueeeee");

      setEmailValid(true);
    } else {
      // console.log("falseeeeeeeee");

      setEmailValid(false);
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/users/resetpassword`,
        user
      );
      console.log(response);
      switch (response.status) {
        case 201:
          console.log("successfully got response");
          history.push("/login");
          break;
        case 409:
          alert(response.data.errors);
          break;
        case 500:
          alert(response);
          break;
        case 400:
          alert(response);
          break;
        case 202:
          alert(response);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("Sign Up failed");
      setError(true);
      const errorMessage = error;
      alert(errorMessage);
    }

    // fetch(`${process.env.REACT_APP_BASE_URL}api/users/resetpassword`, {
    //   method: "POST",
    //   headers: {
    //     "Content-type": "application/json",
    //   },
    //   body: JSON.stringify(user),
    // })
    //   .then((result) => {
    //     console.log(result.json());
    //     history.push("/login");
    //     //console.log("OTP sent");
    //     //history.push("/f-a");
    //   })
    //   .catch((err) => {
    //     //console.log(err);
    //     //console.log("OTP not sent");
    //     setError(true);
    //   });
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}></div>
      <div className={classes.formContainer}>
        <img src={Logo} className="logo" alt="logo" />

        <div className={classes.form}>
          <Typography className={classes.title} variant="h3">
            Reset Password
          </Typography>
          <Typography className={classes.subTitle}>
            Forgot your password? Let's get you a new one.
          </Typography>
          <Typography className={classes.contentText}>
            One-time passsword needed to reset your password, Click the button
            below to send it over your registered contact number.
          </Typography>
          <form autoComplete={"off"}>
            <TextField
              id="email"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showEmailValidity ? (
                      emailValid === true ? (
                        <Fade in={emailValid}>
                          <CheckIcon className={classes.fieldIcons} />
                        </Fade>
                      ) : (
                        <Fade in={!emailValid}>
                          <CloseIcon className={classes.fieldIcons} />
                        </Fade>
                      )
                    ) : (
                      ""
                    )}
                  </InputAdornment>
                ),
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={user.email}
              onChange={(e) => {
                emailValue(e);
              }}
              onFocus={() => {
                setShowEmailValidity(false);
              }}
              onBlur={(e) => {
                if (e.target.value.length > 0) {
                  setShowEmailValidity(true);
                } else {
                  setShowEmailValidity(false);
                }
              }}
              margin="normal"
              placeholder="Email Address"
              type="email"
              fullWidth
            />

            <MuiPhoneNumber
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              fullWidth
              name="phone"
              label="Contact No."
              data-cy="user-phone"
              defaultCountry={"ca"}
              value={user.phoneNumber}
              required
              margin="normal"
              onChange={(e) => {
                setUser({ ...user, phoneNumber: e });
              }}
              // onKeyPress={handleKeyPress}
            />
            <div className={classes.formButtons}>
              <Button
                disabled={
                  user.email.length === 0 ||
                  user.phoneNumber.length === 0 ||
                  emailValid === false
                }
                className={classes.resetBtn}
                fullWidth
                variant="contained"
                size="large"
                type="submit"
                disableTouchRipple={true}
                disableFocusRipple={true}
                onClick={(e) => handleReset(e)}
              >
                Send Password
              </Button>
            </div>
            {error && (
              <Grow in={error}>
                <Typography className={classes.errorMessage}>
                  Something went wrong!!!
                </Typography>
              </Grow>
            )}
            {error && (
              <Grow in={error}>
                <Typography className={classes.errorMessage}>
                  one-time password not sent.
                </Typography>
              </Grow>
            )}
          </form>
        </div>
        <Typography color="primary" className={classes.copyright}>
          OBHG © {new Date().getFullYear()}
        </Typography>
      </div>
    </Grid>
  );
};

export default ResetPassword;
