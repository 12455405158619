import React, {useEffect, useState} from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Fade,
  Grow,
  makeStyles,
  createStyles,
  InputAdornment,
  Loader
} from "@material-ui/core";
import Hospital from "../assets/images/obhg_web_portal_background.jpg";
import Logo from "../assets/images/logo.png";
import "../assets/css/common.css";
import { useHistory } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import MuiPhoneNumber from "material-ui-phone-number";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

// styles
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        top: 0,
        left: 0,
      },
    },
    logotypeContainer: {
      backgroundImage: `url(${Hospital})`,
      backgroundSize: "cover",
      backgroundRepeat: "no repeat",
      width: "60%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "50%",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    formContainer: {
      width: "40%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "60%",
      },
    },
    form: {
      width: 320,
      padding: 24,
      justifyContent: "center",
      alignItems: "center",
      background: "#F1F1F1",
      borderRadius: 12,
      marginTop: 24,
      // [theme.breakpoints.down("md")]: {
      //   marginTop: 12,
      //   background: "#f8f9fa",
      //   borderRadius: 0,
      // },
    },
    greeting: {
      fontWeight: 500,
      marginTop: theme.spacing(4),
      color: "#28A745",
      letterSpacing: 3,
      fontSize: 24,
    },
    subGreeting: {
      fontWeight: 500,
      textAlign: "center",
      marginTop: theme.spacing(2),
    },
    title: {
      color: "#36567F",
      fontWeight: "700",
      letterSpacing: 1,
      fontSize: 24,
      marginBottom: 8,
    },
    errorMessage: {
      textAlign: "center",
      fontSize: 12,
      fontWeight: "600",
      color: "red",
      padding: "20px 0px 0px 0px",
    },
    textFieldUnderline: {
      "&:before": {
        borderBottomColor: "#747272",
      },
      "&:after": {
        borderBottomColor: "#36567F",
      },
      "&:hover:before": {
        borderBottomColor: "#36567F !important",
      },
    },
    textField: {
      borderBottomColor: "#747272",
    },
    formButtons: {
      width: "100%",
      marginTop: theme.spacing(4),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    loginContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px 0px 0px 0px",
    },
    loginBtn: {
      textTransform: "none",
      fontWeight: 400,
      color: "#FF6B00",
      display: "contents",
    },
    copyright: {
      paddingBottom: 4,
      marginTop: 24,
      color: "#2B2828",
      whiteSpace: "nowrap",
      textAlign: "center",
    },
    signUpBtn: {
      backgroundColor: "#FF6B00",
      color: "#FFF",
      textTransform: "none !Important",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#365680",
      },
    },
    fieldIcons: {
      height: 24,
      width: 24,
      cursor: "none",
      pointerEvents: "none",
      color: "#FF6B00",
    },
    passErrMessage: {
      fontSize: 12,
      fontWeight: "600",
      color: "red",
      padding: "5px 0px 0px 0px",
    },
    passFieldIcons: {
      height: 24,
      width: 24,
      cursor: "pointer",
      color: "#FF6B00",
    },
  })
);

const SignUp = () => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const history = useHistory();
  const [emailValid, setEmailValid] = useState(true);
  const [showEmailValidity, setShowEmailValidity] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [canRegister, setCanRegister] = useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}api/users/all-users`).then((res) => {
      const allUsers = res.data.users;
      if (allUsers.length > 0) {
        history.push("/login");
      } else {
        setCanRegister(true);
      }
    });
  }, []);

  const [user, setUser] = useState({
    email: "",
    phoneNumber: "",
    password: "",
    confirmPass: "",
    error: "",
  });

  const emailValue = (e) => {
    let trimmedValue = e.target.value.trim();
    setUser({ ...user, email: trimmedValue });
    // console.log(trimmedValue);
    const validateEmail = (email) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    };

    if (validateEmail(trimmedValue) === true && trimmedValue !== "") {
      // console.log("trueeeee");

      setEmailValid(true);
    } else {
      // console.log("falseeeeeeeee");

      setEmailValid(false);
    }
  };

  const showPass = (e) => {
    setShowPassword(!showPassword);
  };

  const showConfirmPass = (e) => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSignUp = async () => {
    if (user.password === user.confirmPass) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/users/register`,
          user
        );
        console.log(response);

        switch (response.status) {
          case 201:
            console.log("Sign Up success");
            history.push("/login");
            break;
          case 409:
            alert(response.data.errors);
            break;
          case 500:
            alert(response);
            break;
          case 400:
            alert(response);
            break;
          case 202:
            alert(response);
            break;
          default:
            break;
        }
      } catch (error) {
        console.log("Sign Up failed");
        const errorMessage = error;
        alert(errorMessage);
      }
    } else {
      // console.log("Passwords are not matching");
    }
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}></div>
      <div className={classes.formContainer}>
        <img src={Logo} className="logo" alt="logo" />
        {!canRegister && <CircularProgress />}
        {canRegister && (
          <form autoComplete={"off"} className={classes.form}>
            <Typography className={classes.title}>Sign Up</Typography>
            <>
              <TextField
                id="email"
                name="email"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showEmailValidity ? (
                        emailValid === true ? (
                          <Fade in={emailValid}>
                            <CheckIcon className={classes.fieldIcons} />
                          </Fade>
                        ) : (
                          <Fade in={!emailValid}>
                            <CloseIcon className={classes.fieldIcons} />
                          </Fade>
                        )
                      ) : (
                        ""
                      )}
                    </InputAdornment>
                  ),
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={user.email}
                onChange={(e) => {
                  // setUser({ ...user, email: e.target.value.trim() });
                  emailValue(e);
                }}
                onFocus={() => {
                  setShowEmailValidity(false);
                }}
                onBlur={(e) => {
                  if (e.target.value.length > 0) {
                    setShowEmailValidity(true);
                  } else {
                    setShowEmailValidity(false);
                  }
                }}
                margin="normal"
                placeholder="Email Address"
                type="email"
                fullWidth
              />
              <MuiPhoneNumber
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                fullWidth
                id="contact"
                name="contact"
                label="Contact No."
                data-cy="user-phone"
                defaultCountry={"ca"}
                value={user.phoneNumber}
                required
                margin="normal"
                onChange={(e) => {
                  setUser({ ...user, phoneNumber: e });
                  // console.log(e);
                }}
                // onKeyPress={handleKeyPress}
              />
              <TextField
                id="password"
                name="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showPassword === true ? (
                        <VisibilityOffIcon
                          onClick={showPass}
                          className={classes.passFieldIcons}
                        />
                      ) : (
                        <VisibilityIcon
                          onClick={showPass}
                          className={classes.passFieldIcons}
                        />
                      )}
                    </InputAdornment>
                  ),
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={user.password}
                onChange={(e) =>
                  setUser({ ...user, password: e.target.value.trim() })
                }
                margin="normal"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
              />
              <TextField
                id="confirmPass"
                name="confirmPass"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showConfirmPassword === true ? (
                        <VisibilityOffIcon
                          onClick={showConfirmPass}
                          className={classes.passFieldIcons}
                        />
                      ) : (
                        <VisibilityIcon
                          onClick={showConfirmPass}
                          className={classes.passFieldIcons}
                        />
                      )}
                    </InputAdornment>
                  ),
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={user.confirmPass}
                onChange={(e) => {
                  let error = '';
                  function validate(password) {
                    let minMaxLength = /^[\s\S]{8,32}$/,
                      upper = /[A-Z]/,
                      lower = /[a-z]/,
                      number = /[0-9]/,
                      special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

                    if (minMaxLength.test(password) &&
                      upper.test(password) &&
                      lower.test(password) &&
                      number.test(password) &&
                      special.test(password)
                    ) {
                      return true;
                    }
                    return false;
                  }
                  if (!validate(e.target.value.trim())) {
                    error = 'Password must have at least 8 characters, 1 lower case, 1 upper case and 1 special character.';
                  }
                  setUser({ ...user, confirmPass: e.target.value.trim(), error: error })
                }
                }
                margin="normal"
                placeholder="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                fullWidth
              />
              {user.password !== user.confirmPass && (
                <Grow in={user.password !== user.confirmPass}>
                  <Typography className={classes.passErrMessage}>
                    Passwords must be same
                  </Typography>
                </Grow>
              )}
              <div className={classes.formButtons}>
                <Button
                  disabled={
                    user.email.length === 0 ||
                    user.phoneNumber.length === 0 ||
                    user.password.length === 0 ||
                    user.confirmPass.length === 0 ||
                    user.password !== user.confirmPass ||
                    user.error !== "" ||
                    emailValid === false
                  }
                  className={classes.signUpBtn}
                  fullWidth
                  variant="contained"
                  size="large"
                  disableTouchRipple={true}
                  disableFocusRipple={true}
                  onClick={handleSignUp}
                >
                  Sign Up
                </Button>
              </div>
              <div className={classes.loginContainer}>
                <Button
                  color="primary"
                  size="large"
                  className={classes.loginBtn}
                  disableTouchRipple={true}
                  disableFocusRipple={true}
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  Already registered ? Login
                </Button>
              </div>
              {user.error !== '' && (
                <Fade in={user.error}>
                  <>
                    <div className={classes.errorSection}>
                      <Typography className={classes.errorMessage}>
                        {user.error}
                      </Typography>
                    </div>
                  </>
                </Fade>
              )}
              {error && (
                <Grow in={error}>
                  <Typography className={classes.errorMessage}>
                    Invalid Details
                  </Typography>
                </Grow>
              )}

              {/* {showEmailValidity === true && emailValid === false && (
              <Grow in={!emailValid}>
                <Typography className={classes.errorMessage}>
                  Invalid email !!!
                </Typography>
              </Grow>
            )} */}
            </>
          </form>
        )}
        <Typography className={classes.copyright}>
          OBHG © {new Date().getFullYear()}
        </Typography>
      </div>
    </Grid>
  );
};

export default SignUp;
